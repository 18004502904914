import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import SmallBox from 'components/smallBox';
import { graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "fujitsu"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    fujitsucoupon1: file(relativePath: { eq: "others/fujitsu-coupon1.png"}) { ...normalImg },
    fujitsucoupon2: file(relativePath: { eq: "others/fujitsu-coupon2.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`富士通シークレットクーポン専用ページ`}</h1>
    <p>{`当サイトでは、富士通様のご厚意によりシークレットクーポンを発行していただいている。基本的にプレミアムモデルが多くパソコンとしては高価なものが多いがクーポンを使用することで一般よりお得にパソコンを購入できる。`}</p>
    <p>{`大学生向けやビジネス用途として軽量のモバイルパソコンは重量・バッテリー・堅牢性の観点から非常に優秀であり、スタンダードノートは初心者でも安心して使うことができる。`}</p>
    <p><em parentName="p">{`是非活用してお得な価格で手に入れて欲しい`}</em>{`。クーポン適用についてはページ下部に記載している。`}</p>
    <h2 {...{
      "id": "クーポンコードクーポン適用対象機種",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%AF%E3%83%BC%E3%83%9D%E3%83%B3%E3%82%B3%E3%83%BC%E3%83%89%E3%82%AF%E3%83%BC%E3%83%9D%E3%83%B3%E9%81%A9%E7%94%A8%E5%AF%BE%E8%B1%A1%E6%A9%9F%E7%A8%AE",
        "aria-label": "クーポンコードクーポン適用対象機種 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`クーポンコード/クーポン適用対象機種`}</h2>
    <div style={{
      fontSize: '32px',
      marginTop: '15px'
    }}>SPLSJ</div>
    <p>{`対象機種は下記などほとんどのモデル。多くのカスタムメイドモデルは`}<em parentName="p">{`通常割引価格と比べて2-7%の割引が適用`}</em>{`されます(多くは3-4%)。`}</p>
    <p>{`WUX/H1、WU3/H2、WU2/H1、WU2/H1、WN1/H1、WMB/J1、WA3/J1、WA3/H2、WFX/H2、WF2/H2、WF1/H3、WFB/H3、WD2/H2、WD1/H2、WN1/J3、WA3/J3、WU5/J3、WU4/J3、WU3/J3、WU2/J3 5G、WU2/J3、WC1/H3、WMB/J3、WP1/J3`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=183092.10000022&type=3&subid=0" target="_blank" rel="nofollow noopener">富士通</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=183092.10000022&type=3&subid=0" />
    </SmallBox>
    <h3 {...{
      "id": "注意事項",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%B3%A8%E6%84%8F%E4%BA%8B%E9%A0%85",
        "aria-label": "注意事項 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`注意事項`}</h3>
    <p>{`当クーポンは他サイト（MYCLOUD会員向けページ含む）へ遷移した場合や電話相談窓口では、基本的に使用できません。当サイトからのリンク経由でお使いください。`}</p>
    <h2 {...{
      "id": "クーポン適用方法",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%AF%E3%83%BC%E3%83%9D%E3%83%B3%E9%81%A9%E7%94%A8%E6%96%B9%E6%B3%95",
        "aria-label": "クーポン適用方法 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`クーポン適用方法`}</h2>
    <p>{`適用方法はまずは商品をカートに入れて、カートの中身を確認すると、クーポンコードの入力欄が表示されている。そこにクーポンカードを入れて適用ボタンをクリックすると適用後の金額が表示されて完了である。`}</p>
    <h3 {...{
      "id": "商品をカートに入れる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%95%86%E5%93%81%E3%82%92%E3%82%AB%E3%83%BC%E3%83%88%E3%81%AB%E5%85%A5%E3%82%8C%E3%82%8B",
        "aria-label": "商品をカートに入れる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`商品をカートに入れる`}</h3>
    <Image {...props} name="fujitsucoupon1" alt="富士通パソコンのクーポン適用" mdxType="Image" />
    <h3 {...{
      "id": "入力欄にクーポンコードを入れて適用",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%85%A5%E5%8A%9B%E6%AC%84%E3%81%AB%E3%82%AF%E3%83%BC%E3%83%9D%E3%83%B3%E3%82%B3%E3%83%BC%E3%83%89%E3%82%92%E5%85%A5%E3%82%8C%E3%81%A6%E9%81%A9%E7%94%A8",
        "aria-label": "入力欄にクーポンコードを入れて適用 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`入力欄にクーポンコードを入れて適用`}</h3>
    <Image {...props} name="fujitsucoupon2" alt="富士通パソコンのクーポン適用" mdxType="Image" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      